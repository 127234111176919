import './App.css';
import underConstruction from './under_construction.png'
import logo from './icon.png';

function App() {
  return (
    <div className="App">
      <header className="App-header"></header>
	<div id="banner">
		<h1 id="banner-text">Network Questions</h1>
		<img id="icon" src={logo} alt="logo" />
	</div>
	<div id="menu">
			<div className="menu-item"><a href="#home">Home</a></div>
	  		<div className="menu-item">
					<a className="dropdown" href="#questions">Questions</a>
			 	<div className="dropdown-content">
    						<ul className="dropdown-list">
							<li className="sub-memu-item">Item 1</li>	
							<li className="sub-memu-item">Item 2</li>
							<li className="sub-memu-item">Item 3</li>
						</ul>
  				</div>
			</div>
	  </div>

	  <img id="under_construction_img" src={underConstruction} alt="under construction" />

     </div>

  );
}

export default App;
